import React, {useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Location } from './components/Tables/Location'
import { LocationDetailsView } from './components/LocationDetailsView'
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import { LocationEdit } from './components/Location/LocationEdit'
import { WhiteBtn } from '../jobs/component/WhiteBtn'
import { LocationAdd } from './components/LocationAdd'
import { FormattedMessage } from 'react-intl'
export const LocationManage = () => {
const [showModel, setShowModel] = useState(false)
const [activeComponent, setActiveComponent] = useState<any>({});
      const AllModel = [
        {name:LocationDetailsView, 
        label:"LocationDetailsView"},
        {
          name:LocationEdit,
          label:"LocationEdit"
        }, 
        {name:LocationAdd, 
        label:"LocationAdd"}
      ]
      const openPopup = (index: any) => {  
        const activeComp = AllModel.filter(function (e, i) {
          return i == index
        });      
        setActiveComponent(activeComp[0]);
        setShowModel(true)
      };
      const HandleModel = (data) => {
        setShowModel(data)
    }
  return (
    <MainWrapper>
    <div className='py-3 px-10 mx-auto'>
        <div className='mb-2'>
          <div className='flex justify-between items-center'>
          <div>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TITLE" defaultMessage="Manage Addresses" /></h1>
    <p><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TEXT" defaultMessage="Use this page to manage your Addresses" /></p>
    </div>
   
 <WhiteBtn onClick={() => openPopup(2)} svg={ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>} title={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.BUTTON.TITLE" defaultMessage="Add Address" />}></WhiteBtn>
    </div>
    
   </div>
    <Location Viewicon={()=> openPopup(0)} onClick = {(() => openPopup(1)) } />
     <ModelWrapperWithButton nofooter = {activeComponent.name == LocationDetailsView ? true : false}  showModal={showModel} onCheckModel={HandleModel}>
             {<activeComponent.name />}
            </ModelWrapperWithButton>
        </div></MainWrapper>
  )
}
