import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import { Input } from "sr/helpers";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
export const JobPostNew = () => {
    const intl = useIntl()
    const Data = [
        {name:"Home Cleaner"},
        {name:"Apartment Cleaner"}, 
        {name:"Office Cleaner"},
        {name:"Bathroom Cleaners"},
        {name:"Floor Cleaner"},
        {name:"All Cleaner"},
        {name:"Flat Cleaner"},
        {name:"Kitchen Cleaner"},
        {name:"Carpet shampooing"},
        {name:"House Cleaner"},
        {name: "Sofa Cleaner"},
        {name: "Carpet Cleaner"},
        {name: "Floor Cleaner"},
        {name: "Tiles Cleaner"},
        {name: "Marble Polishing"},
        {name: "Mattress Cleaner"},
        {name: "Curtains Cleaner"},
        {name: "Blinds Cleaner"},
        {name: "Facade Cleaner"},
        {name: "Interior Designing"},
        {name: "Painting"},
        {name: "Pest Control"},
        {name: "Office Cleaner"},
        {name: "Hotel Cleaner"},
        {name: "AC Cleaner"},
        {name: "Commercial Cleaner"},
        {name: "Bird Netting"},
        {name: "Swimming Pool Cleaner"},
        {name: "School Cleaner"},
        {name: "Car Cleaner"}

    ]
  return (
  <MainWrapper>
       <div className="py-3 lg:px-10 lg:max-w-4xl h-screen">
        <div className="text-center lg:py-10 py-5">
            <p className="text-2xl font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.TITLE" defaultMessage="Book your Task" /></p>
        </div>
        <Input inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0" placeholder={intl.formatMessage({id:'INDIVIDUAL.BOOKTASK.INPUT.PLACEHOLDER', defaultMessage:'Describe one task'})}></Input>
        <div className="py-3 flex flex-wrap space-x-3">
            {Data.map((item, index)=> (
            <Link to={'/individual/job-post-details'} className="hover:bg-[#595959] hover:text-white border border-gray-500 py-1 px-4 rounded-lg relative space-x-4 my-1.5 cursor-pointer">{item.name}</Link>
            ))}
            </div>
       </div>
  </MainWrapper>
  );
};
