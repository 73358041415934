import React, {useEffect, useState} from "react";
import AuthNavComponent from "../../module/auth/AuthNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faCircleCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import Footer from "../../module/Footer";
import JobItemCard from "../component/JobItemCard";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { Checkbox, toAbsoluteUrl } from 'sr/helpers'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { Filter } from "../../company/work-order/component/Filter";
import { Input } from "sr/helpers";
import { TableRow } from "./TableRow";
import { FormattedMessage, useIntl } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { usaCities } from "sr/i18n/messages/setting";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { Pagination } from "app/pages/component/Pagination";
import { StatusTab } from "app/pages/company/work-order/component/StatusTab";
import { Empty } from "app/pages/company/candidates/component/Empty";
export const MyJobs = () => {
  const intl= useIntl()
    const [location, setLocation] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [cleanerId, setCleanerId] = useState('')
    const [limit, setLimit] = useState<number>(100)
    const [page, setPage] = useState<number>(1)
    const [totalPage, settotalPage] = useState<number>(0)
    const [Filterdata, setFilterdata] = useState<any>()
    const [statusCount, setStatusCount] = useState()
    const {getData:getWorkOrders, data:workorders, loader:loader, pageination:pageination}:any = useGet()
    let filterDate = ''
    function convertToISOFormat(dateStr) {
      // Create a Date object from the input date string
      const date = new Date(dateStr);
    
      // Convert to ISO string and ensure it includes milliseconds
      const isoString = date.toISOString();
    
      return isoString;
    }
    useEffect(()=> {
      if(pageination) {
        setStatusCount(pageination?.counts)
        console.log(pageination);
        
          settotalPage(Math.ceil(pageination?.total/limit))
      }
    },[pageination])
    if(startDate && endDate) {
      filterDate = convertToISOFormat(startDate)+','+convertToISOFormat(endDate)
      console.log(filterDate);
      
    }
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setCleanerId(user_id)
    },[])


    const stepperIndex = 2; 
    const data: any = {
        id: '398598-98w98e9-9090',
        jobTitle: 'Executive Housekeeper',
        location: 'Pune, Maharashtra',
        jobType: 'Regular/Permenent',
        schedule: 'Morning shift',
        description: 'Supervises all housekeeping employees, hires new employees as needed, discharges employees when necessary and take disciplinary actions when policies are not followed. Evaluates employees in order to upgradethem when openings arise. Plans the work for the housekeeping departmentand distributes assignments accordingly. Assigns regular duties and special duties for the housekeeping staff. Schedulesemployees and assigns extra days off according to occupancyforecast. Maintains a time logbook of all employees within the department. Recruit and train new employees. Assigns new employees to work with experienced help. Checks on the work of these employees occasionally and observes the report made by the supervisors. Approves all supply requisitions, such as those for spreads and bathroom rugs. Maintains a lost-and-found department and is responsible for all lost-and-found items. Determines the rightful owner and send correspondences.',
    };

    useEffect(()=> {
        const fetchWorkOrder = async (cleanerId)=> {
            const queryParams = new URLSearchParams()
            if (cleanerId) queryParams.append('cleaner_id', cleanerId)
              if (startDate && endDate) queryParams?.append('one_time_date>', convertToISOFormat(startDate))  
                if (startDate && endDate) queryParams?.append('one_time_date<', convertToISOFormat(endDate))  
                  if (location) queryParams?.append('location', location)  
              if (page) queryParams?.append('page', page?.toString())
              if (limit)  queryParams?.append('limit', '1000')
                queryParams?.append('status', 'publish')

              let obj = {};
              let filter = {}
              if (Object.keys(obj).length>0) {
               filter['$and'] = [obj]
              }
              queryParams?.append('filter', JSON.stringify(filter))
              await getWorkOrders(() => getWorkorder(queryParams));
        }
        if(cleanerId) {
          fetchWorkOrder(cleanerId)
        }
    },[cleanerId, location, filterDate, page])

  return (
    <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
<ContentWithSpinner loader={loader} height="h-screen">

    <div className="py-3 lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
        <div className='mb-2'>
    <h1 className='text-2xl font-satoshiBold'><FormattedMessage id="ASSIGNED.WORKORDER.TITLE" defaultMessage="My Work Order" />  </h1>
   </div>
   <div className='flex space-x-2 items-center'>
   {/* <DropDown labelClass='text-base' value={location} label={intl.formatMessage({
    id: 'RECRUITER.WORKORDER.FILTER.LOCATION',
    defaultMessage: 'Location',
  })}
 dropdowndiv='mt-1' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION" defaultMessage="Select Location" /></option>
    {usaCities?.map((item, index)=> (
    <option key={index} value={item?.value}>{item?.value}</option>
  ))
}
  </DropDown> */}
  <ReactDatePicker 
    label={<FormattedMessage id="RECRUITER.WORKORDER.FILTER.SCHEDULED.START.DATE" defaultMessage="Scheduled Start date" />} 
    inputClass="rounded-lg" 
    labelClass="mt-0 text-black" 
    InputDivClass="mt-0 w-40"     
    placeholder="Enter company joining date"
    isDefaultSelected={false}
    onChange={(date)=> setStartDate(date)}
    selected={startDate}>
  </ReactDatePicker>
  <ReactDatePicker
    label={<FormattedMessage id="RECRUITER.WORKORDER.FILTER.SCHEDULED.END.DATE" defaultMessage="Scheduled End date" />} 
    inputClass="rounded-lg" 
    labelClass="mt-0 text-black" 
    InputDivClass="mt-0 w-40"
    placeholder="Enter company joining date"
    isDefaultSelected={false}
    onChange={(date)=> setEndDate(date)}
    selected={endDate}>
  </ReactDatePicker>
 </div>
{workorders?.length>0 &&<>
 <StatusTab className="mt-5" data={workorders} setFilterdata={setFilterdata} statusCount={statusCount}/>
   <div className="overflow-x-auto mt-4 rounded-[20px] border border-neutral-200">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.TITLE',
    defaultMessage: 'Title',
  })}
</th>
              
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.NAME',
    defaultMessage: 'Customer Name',
  })}
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.NAME.LOCATION',
    defaultMessage: 'Location',
  })}
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.NAME.SCHEDULE.DATE',
    defaultMessage: 'Schedule Date',
  })}
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.NAME.COMPLETION.DATE',
    defaultMessage: 'Completion Date',
  })}
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  {intl.formatMessage({
    id: 'MYJOB.TABLE.COL.NAME.STATUS',
    defaultMessage: 'Status',
  })}
</th>

            </tr>
        </thead>
        <tbody>

        {Filterdata?.length>0 ? 
        
        Filterdata?.map((data)=> (
        <TableRow data={data}/>
))
: <tr>
<td colSpan={6} className="text-center">
  <Empty text="No records to display" className='py-14'/>
</td>
</tr>
}
          
        </tbody>
    </table>
    </div>
<Pagination page={page} totalPage={totalPage} setPage={setPage}/>
</>}
 </div>
 {!(workorders?.length>0) &&
 <div className='lg:container lg:mt-10 mt-10 px-4 lg:px-0 rounded-xl lg:max-w-6xl block bg-white border border-gray-border mx-auto'>
<div className='lg:container text-center mx-auto items-center justify-center lg:max-w-xl py-10'>
<div className='flex justify-center'>
    <img className='w-20' src={toAbsoluteUrl('/media/img/emtydisplay.png')}></img>
</div>
<div className='text-2xl font-bold'> You have no any assigned any Work Order</div>
    </div> </div>
}
</ContentWithSpinner>
    <Footer /> 
    </>
  );};
