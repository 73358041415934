import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapperWithButton } from '../../component/ModelWrapperWithButton'
import EmailChange from 'app/pages/company/settings/Components/models/EmailChange'
import PasswordChange from './models/PasswordChange'
import ChangePhone from './models/ChangePhone'
import { toAbsoluteUrl } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useGet } from 'app/pages/company/Hooks/Api/useGet'
import { getIndividual } from 'sr/utils/api/individual/getIndividual'
import { useAuth } from 'app/pages/module/auth'
import { updateIndividual } from 'sr/utils/api/individual/updateIndividual'
import NameChange from './models/NameChange'
import PhoneNumberFormat from 'sr/helpers/functions/PhoneNumberFormat'
export const Account = () => {
    const [showModal, setShowModal] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [profileImg, setprofileImg] = useState(null)
    const [userId, setUserid] = useState()
    const {logout} = useAuth()
    const [formData, setFormData] = useState<any>({});
    const HandleModel = (data) => {
        setShowModal(data)
}
const {loader:loader, getData:fetchData, data:data} = useGet()
useEffect(()=> {
const {user_id} = getUserDetails()
setUserid(user_id)
},[])
const childRef = useRef(null);
const fetchDetails  = async (id)=> {
    const params = new URLSearchParams()
      if (userId) params.append('id', id)
        await fetchData(()=> getIndividual(params))
    }
const HandleChange = async () => {
  console.log('ww');
  
    let isValidData: boolean;
    if (childRef.current && childRef.current.validateData) {
      isValidData = await childRef.current.validateData();
    }
    if (childRef.current && childRef.current.submitForm){
      childRef.current.submitForm()
    }    

    if(isValidData) {   
            const result = await updateIndividual({id:userId, ...formData})
            if(result?.success=== true) {
                fetchDetails(result?.data?.id)
                setShowModal(false)
            }
  
    }  
  }
useEffect(()=> {
if(userId) {
    fetchDetails(userId)
}
},[userId])
const HandleData = (data:any) => {
    console.log(data);
    
      setFormData(data)
  }
const AllModels = [
    {
        label: 'NameChange',
        componentName: NameChange,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.BUTTON.TITLE" defaultMessage="Change Name" />
      },
    {
      label: 'EmailChange',
      componentName: EmailChange,
      headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_EMAIL.BUTTON" defaultMessage="Change email" />
    },
    {
        label: 'PasswordChange',
        componentName: PasswordChange,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PASSWORD.BUTTON" defaultMessage="Change password" />
      },
    {
        label:'ChangePhone',
        componentName:ChangePhone,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PHONENO.TITLE" defaultMessage="Change Phone Number" />
    }
    ]

const openModel = (index: any) => {  
    const activeComp = AllModels.filter(function (e, i) {
      return i == index
    });      
    console.log(activeComp[0])
    setActiveComponent(activeComp[0]);

    setShowModal(true)
  };
  const HandleImg = (e) => {
    setprofileImg(URL.createObjectURL(e.target.files[0]))
  }
  return (
    <>
    <div className='flex justify-center border-b border-neutral-200 pb-3 mb-3'>
    <div className="radius-50 container mx-auto my-auto flex justify-center w-[200px]">
        <label htmlFor='img-input'>
        <img alt="Profile" className="rounded-full" src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')} />
        <input onChange={HandleImg} type='file' id='img-input' name='img' accept='image/*' className='hidden'></input>
        </label>
        </div>
    </div>
     <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.TITLE" defaultMessage="Name" /></h2>
            <div>{data?.first_name} {data?.last_name}</div>
        </div>
        <div onClick={()=> openModel(0)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.BUTTON.TITLE" defaultMessage="Change Name" /></div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.EMAIL.TITLE" defaultMessage="Email" /></h2>
            <div>{data?.email}</div>
        </div>
        <div onClick={()=> openModel(1)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_EMAIL.BUTTON" defaultMessage="Change email" /></div>
    </div>
    
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.PASSWORD.TITLE" defaultMessage="Password" /></h2>
            <div>••••••••</div>
        </div>
        <div onClick={() => openModel(2)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PASSWORD.BUTTON" defaultMessage="Change password" /></div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.PHONENO.TITLE" defaultMessage="Phone number" /></h2>
            <div>{PhoneNumberFormat(data?.mobile_number)}</div>
        </div>
        <div onClick={()=> openModel(3)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PHONENO.TITLE" defaultMessage="Change Phone Number" /></div>
    </div>
    {/* <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.AUTHENTICATION.TITLE" defaultMessage="Third party authentication" /></h2>
            <div><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.AUTHENTICATION.TEXT" defaultMessage="No third-party applications have access to your account" />
    </div>
        </div>
    </div> */}
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>{data?.email}</h2>
        </div>
        <div onClick={()=> logout()} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.SIGN_OUT.BUTTON" defaultMessage="Sign out" /></div>
    </div>
    <ModelWrapperWithButton Save={HandleChange} showModal = {showModal} onCheckModel = {HandleModel} headertitle = {activeComponent.headertitle}>
    {<activeComponent.componentName HandleData={HandleData} ref={childRef} HandleModel = {HandleModel}/>}
    </ModelWrapperWithButton>
    </>
  )
}
