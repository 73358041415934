import React, {useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Link } from 'react-router-dom'
import { TopBar } from '../component/TopBar'
import EmailLink from 'sr/helpers/ui-components/EmailLink'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Profile } from './Profile'
import { ModelWrapper } from '../component/ModelWrapper'
import { AvailabilityCalendar } from './component/AvailabilityCalendar'
export const IndividualCleanerProfile = () => {
  const [showModal, setShowModal] = useState(false)
  const [activeTab, setActiveTab] = useState('About')
  const [model, setModel] = useState(false)
  const HandleModelByProps = (data:any)=> {
    setModel(data)
  }
  return (<>
<MainWrapper>
<div className='py-2'>
  <div className='grid grid-cols-12 gap-4'>
    <div className='col-span-3 bg-white rounded-lg'>
    <div className='border-neutral-200 border-b py-4'>
    <h2 className='px-4 text-2xl font-satoshiBold text-[#595959]'>4 Cleaner</h2>
    <p className='px-4 text-xs text-[#595959]'>1 of 4 candidates Cleaner</p>
    </div>
   { [{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {}].map((data)=> (
    <div className=' border-neutral-200 border-b py-4'>
    <h2 className='px-4 text-base font-satoshiBold'>Ritik Sharma</h2>
    <p className='px-4 text-xs text-[#595959]'>Bhopal, Madhya Pradesh</p>
    </div>
))}
    </div>
    <div className='col-span-6'>
    <div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem] shadow-boxshadowpage'>
<Profile />
<div className='flex gap-8 pb-1.5 mt-5'>
                    <span onClick={(e)=> setActiveTab('About')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab === 'About' ? 'border-b-4 border-black text-black' : '' }`}>About</span>
                    <span onClick={(e)=> setActiveTab('jobs')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab === 'jobs' ? 'border-b-4 border-black text-black' : ''}`}>Completed Jobs</span>
                </div>
                <div className="border-b -mt-1.5"></div>
<div className='mt-8'>
  {activeTab === 'About' &&
<div>
        <h1 className='text-xl font-satoshiBold pb-2'>Training Completed</h1>
        <div className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>Physical Cleaning Tasks and Safety</span>
              <div className='text-base'>Sep 2022 - Nov 2022
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil assumenda odio reiciendis optio rem ipsum libero exercitationem atque dolorum? Mollitia laudantium totam quam sed provident cumque aspernatur maiores quas iure?</p>
            </div>
        </div>
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Medical Condition</h1>
        <div className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>Condition - BP issue</span>
              <div className='text-base'>Since When - 05-06-2023  </div>
        </div>
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Preferences</h1>
        <div className='pb-4 mb-4'>
            <span className='text-base font-satoshiBold text-black'>Address - Delhi, India </span>
              <div className='text-base'>Radius (in Miles) - 3</div>
              <div className='text-base'>Job Typ - Full time</div>
              <div className='text-base'>Min hours Work(per week) - 30</div>
        
       </div></div></div>
 </div> }
 {activeTab === 'jobs' && 
 [{}, {}, {}].map(()=> (
 <div className='border-b border-neutral-200 pb-4 mt-4'>
 <div className='text-xl font-satoshiBold mb-4'> Support existing Node App Backend of mobile Apps</div>
<div className='flex space-x-2 items-center'>
  <div className='flex'>
    {[{}, {}, {}, {}, {}].map(()=> (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#EAB308" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#EAB308]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>  ))}
</div>
<span className='font-satoshiBold'>5.00</span>
<span className='text-xs'>Jan 14, 2022 - Aug 25, 2023</span></div>
<p className='italic'>"Very good work I was impressed how he straight fixed the problems in quick turnaround, without the project having a clear documentation"</p>
</div>
 ))}
    </div>
   </div>
   </div>
    </div>
    <div className='col-span-3'>
    <div className='bg-[#FAF9F8] py-10 px-5'>
      <div className='text-center mb-5'>
      <p className='font-satoshiBold text-base mb-1'>Rate:</p>
      <span className='text-3xl font-satoshiBold'>$53.07/hr</span>
      </div>
  <div className='flex w-full justify-center'>
<button onClick={()=> setModel(true)} type="submit" className='mt-5 font-satoshiBold text-[#2557A7] border border-neutral-400 bg-white px-4 py-1 rounded-lg'>Continue</button>
</div>
<hr className='border border-gray-200 my-5' />
      </div>
    </div>
</div></div>
</MainWrapper>
{model &&
 <ModelWrapper background="bg-[#00000099]" opacity="opacity-100" topPosition="inset-0" HandleModelByProps={HandleModelByProps}  width="100%" position="fixed" topClass="flex w-full">
 <AvailabilityCalendar onClick={()=> setModel(false)} />
     </ModelWrapper>
}
     </> )
}
