import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "sr/helpers";
import { ModelWrapper } from "../../component/ModelWrapper";
import { AvailabilityCalendar } from "./AvailabilityCalendar";
import { FormattedMessage } from "react-intl";
export const CleanerProfileCard = () => {
  const [model, setModel] = useState(false)
  const HandleModelByProps = (data:any)=> {
    setModel(data)
  }
  return (<>
    <div className="bg-white border border-neutral-300 rounded-lg mb-3">
    <div className="lg:flex block lg:space-x-5 p-5">
      <div className="lg:w-[26%] w-full text-center">
        <div className="mx-auto mb-3 flex justify-center lg:block">
          <img
            alt="Profile"
            className="rounded-full w-44 h-44"
            src={toAbsoluteUrl('/media/img/news-2.jpg')}
          />
        </div>

        <Link to={'/individual/cleaner-profile-details'}>
        <div className="mb-3 text-base font-satoshiBold text-[#2557A7] cursor-pointer">
        <FormattedMessage id="INDIVIDUAL.CLEANERS.VIEW.PROFILE" defaultMessage="View Profile and Reviews" />
        </div></Link>
        <div onClick={()=> setModel(true)} //to={'/individual/confirm'} 
        className="mb-3 text-center font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy rounded-full">
          <FormattedMessage id="INDIVIDUAL.CLEANERS.SELECT_CONTINUE.BUTTON" defaultMessage="Select and Continue" />
        </div>

        <p className="text-center lg:block hidden mt-3">
        <FormattedMessage id="INDIVIDUAL.CLEANERS.SUGGEST.INFORMATION" defaultMessage="You can chat with your Tasker, adjust task details, or change the task time after booking." />

        </p>
      </div>
      <div className="lg:ml-3 flex-1 mt-3 lg:mt-0">
        <div className="flex justify-between lg:pb-5 pb-2">
          <p className="text-xl font-satoshiBold">Vishal Rathaur</p>
          <p className="text-xl font-satoshiBold">$53.07/hr</p>
        </div>
        <div className="items-center flex divide-x divide-solid divide-slate-400 gap-1">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#EAB308"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 text-[#EAB308]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              ></path>
            </svg>
            <div className="text-xs text-gray-500 font-satoshiBold font-bold">
              3.8
            </div>
          </div>
          <div className="text-xs	 text-gray-500 font-satoshiBold font-bold pl-1">
            (101   <FormattedMessage id="CLEANER.JOBS.REVIEWS" defaultMessage="Reviews" />)
          </div>
        </div>
        <div className="font-satoshiBold text-base text-[#2557A7]">1 Cleaner Help Tasks</div>
        <div className="text-base">6 Home Improvement tasks overall</div>
        <div className="flex flex-col lg:p-6 p-4  bg-gray-50 border border-gray-border rounded-xl lg:mt-10 mt-4">
            <div className="font-satoshiBold text-base"><FormattedMessage id="INDIVIDUAL.CLEANERS.QUESTION" defaultMessage="How I can help:" /></div>
            <div>
            <p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit, ad nihil?</p>
            </div>
           <Link className="mt-2 text-sm font-bold font-satoshiBold text-[#275df5] cursor-pointer" to={''}><FormattedMessage id="INDIVIDUAL.CLEANERS.READMORE.BUTTON" defaultMessage="Read More" /></Link>
        </div>
      </div>
    </div></div>
    {model && 
    <ModelWrapper background="bg-[#00000099]" opacity="opacity-100" topPosition="inset-0" HandleModelByProps={HandleModelByProps}  width="100%" position="fixed" topClass="flex w-full">
<AvailabilityCalendar onClick={()=> setModel(false)} />
    </ModelWrapper>
}
    </> );
};
