import React, {useState} from 'react'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
export const ChecklistForm = () => {
  const intl = useIntl()
    const [checklistType, setChecklistType] = useState('')
    const [subtype, setsubtype] = useState('')
  return (
    <>
    <Input label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLIST.TITLE" defaultMessage="Tasklist Name" />}
                        placeholder={intl.formatMessage({id:'INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLIST.PLACEHOLDER', defaultMessage:'Enter Tasklist Name'})}
                        type={'text'}
                        inputClass = 'border border-zinc-800 rounded-lg h-11'>
                    </Input>
    <DropDown dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLISTTYPE.TITLE" defaultMessage="Tasklist Type" />} value={checklistType} onChange={(e) => setChecklistType(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLISTTTYPE.SELECT" defaultMessage="Select Tasklist Type" /></option>
                        <option value='Daily'>Daily</option>
                        <option value='Weekly'>Weekly</option>
                    </DropDown>
                  
                    <DropDown dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.SUBTYPES.TITLE" defaultMessage="Sub Types" />} value={subtype} onChange={(e) => setsubtype(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''>{<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.SUBTYPES.PLACEHOLDER" defaultMessage="Select Sub Types" />}</option>
                        <option value='Hospital'>Hospital</option>
                        <option value='Office'>Office</option>
                    </DropDown>
                    
                    </>
  )
}
