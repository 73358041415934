import React from 'react'
import { FormattedMessage } from 'react-intl'
export const LocationDetailsView = () => {
  return (
    <div className='py-3 px-10'>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STREETADDRESS" defaultMessage="Street Address" />:</span>
      <span>Los Angeles</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.CITY" defaultMessage="City" />:</span>
      <span>Vishal Rathaur</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STATE" defaultMessage="State" />:</span>
      <span>Uttar pradesh</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ZIPCODE" defaultMessage="Zip Code" />:</span>
      <span>1212544</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.COUNTRY.TITLE" defaultMessage="Country" />:</span>
      <span>USA</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.LATITUDE.TITLE" defaultMessage="Latitude" />:</span>
      <span>50.5040</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.LONGITUDE.TITLE" defaultMessage="Longitude" />:</span>
      <span>50.5040</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ADDRESSTYPE.TITLE" defaultMessage="Address Type" />:</span>
      <span>Residencial</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.NOOFROOMS" defaultMessage="No of Rooms" />:</span>
      <span>4</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS" defaultMessage="No of Bathrooms" />:</span>
      <span>4</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.TOTALAREA" defaultMessage="Total Area" /></span>
      <span>1530</span>
    </div>
    
  </div>
  )
}
