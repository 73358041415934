import React, {useState} from 'react'
import { ModelWrapper } from '../../component/ModelWrapper'
import { Link } from 'react-router-dom'
import { ActionBtn } from './ActionBtn'
import { JobPostEdit } from './JobPostEdit'
export const TableRow = () => {
    const [editModel, setEditModel] = useState(false)
    const HandleModelByProps = (data) => {
        setEditModel(data)
       }
  return (
    <>
      
        <tr className="bg-white border-b  hover:bg-gray-50">
            
            <td className="w-4 p-4">
          
                <div className="flex items-center">
                    <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
                    <label htmlFor="checkbox-table-1" className="sr-only">checkbox</label>
                </div>
             
            </td>
          
            <td className="px-6 py-4">
            <Link to={'/individual/job-details'}>
                Vishal Rathaur
                </Link>
            </td>
           
            <td className="px-6 py-4">
            50$
            </td>
    
            <td className="px-6 py-4">
            08/21/2023
            </td>
            <td className="px-6 py-4">
           
            Open
            </td>
            <td className="px-6 py-4">
           
            <ActionBtn editonClick={()=> setEditModel(!editModel)}/>
           </td>   
        </tr>
        {editModel ?
    <JobPostEdit HandleModelByProps={HandleModelByProps}/>: null}
  </>)
}
