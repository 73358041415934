import React from 'react'
import { FormattedMessage } from 'react-intl'
export const Location = (props) => {
  return (
    <div className="overflow-x-auto mt-4 rounded-[20px] border border-neutral-200">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STREETADDRESS" defaultMessage="Street Address" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.CITY" defaultMessage="City" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STATE" defaultMessage="State" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ZIPCODE" defaultMessage="Zip Code" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.COUNTRY.TITLE" defaultMessage="Country" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ACTIONs" defaultMessage="Action" />
                </th>
            </tr>
        </thead>
        <tbody>
          {[{}].map((data)=> (
             <tr className="bg-white border-b  hover:bg-gray-50">
             <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
            
                 <div className='text-base'>los angeles</div>
                 
             </th>
             <td className="px-6 py-4">
                 Vishal Rathaur
             </td>
           
     
             <td className="px-6 py-4">
             08/21/2023
             </td>
             <td className="px-6 py-4">
             08/21/2023
             </td>
             <td className="px-6 py-4">
            USA
             
             </td>
             <td className="px-6 py-4">
            
             <div className='flex items-center space-x-4'>
                <div className='border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                  <div onClick={props.onClick}  className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
</svg>
</div>
<div onClick={props.Viewicon}  className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

</div>
<div className='py-1.5 px-2 cursor-pointer hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg></div>


                </div></div>
            </td>
         </tr>
))}
          
        </tbody>
    </table></div>
  )
}
