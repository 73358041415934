import React, {useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Checklist } from './components/Tables/Checklist'
import { ChecklistForm } from './components/forms/ChecklistForm'
import { TaskForm} from './components/forms/TaskForm'
import { Task } from './components/Tables/Task'
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import { WhiteBtn } from '../jobs/component/WhiteBtn'
import { ChecklistAdd } from './components/ChecklistAdd'
import { FormattedMessage } from 'react-intl'
export const ChecklistManage = () => {
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [showModel, setShowModel] = useState(false)
    const [checklistAdd, setChecklistAdd] = useState(false)
    const AllModel = [
        {name:'ChecklistEdit',
        componentName:ChecklistForm },
        {name:'TaskEdit',
        componentName:TaskForm },
        {name:'Task',
        componentName:Task }
    ]
    const openPopup = (index: any) => {  
        const activeComp = AllModel.filter(function (e, i) {
          console.log(i == index)
          return i == index
        });      
        setActiveComponent(activeComp[0]);
        console.log('activeComponent', activeComponent)
        setShowModel(true)
      };
      const HandleModel = (data) => {
        setShowModel(data)
    }
    const onCheckModel = (data) => {
      setChecklistAdd(false)
    }
  return (
    <MainWrapper>
    <div className='py-3 px-10 mx-auto'>
    <div className='mb-2'>
      <div className='flex items-center justify-between'>
      <div>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TITLE" defaultMessage="Manage Task List" /></h1>
    <p><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TEXT" defaultMessage="Use this page to manage your Task List" /></p>
   </div>
   <WhiteBtn onClick={()=> setChecklistAdd(true)} svg={ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>} title={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.ADDTASKLIST.BUTTON" defaultMessage="Add Tasklist" />}></WhiteBtn></div></div>
    <Checklist Viewicon={() => openPopup(2)}  onClick= { () => openPopup(0) } />       
    <ModelWrapperWithButton showModal={showModel} onCheckModel={HandleModel}>
              {<activeComponent.componentName />}
             </ModelWrapperWithButton>
             <ModelWrapperWithButton showModal={checklistAdd} onCheckModel={onCheckModel}>
       <ChecklistAdd />
       </ModelWrapperWithButton>

    </div>
    </MainWrapper>
  )
}
