import React, { useEffect, useState, useRef } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import CleanerDocuments from "./CleanerDocuments";
import CleanerReference from "./CleanerReference";
import CleanerEmployment from "./CleanerEmployment";
import JobType from "./JobType";
import CleanerTraining from "./CleanerTraining";
import CleanerMedicalCondition from "./CleanerMedicalCondition";
import CleanerPreferences from "./CleanerPreferences";
import { FormattedMessage } from "react-intl";
import { faMapMarker, faBriefcase, faEnvelope, faPhone, faPencil } from '@fortawesome/free-solid-svg-icons'
import Footer from "../module/Footer";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getReference } from "sr/utils/api/cleaner/getReference";
import { getEmployment } from "sr/utils/api/cleaner/getEmployment";
import { getJobType } from "sr/utils/api/cleaner/getJobType";
import { getMedicalCondition } from "sr/utils/api/cleaner/getMedicalCondition";
import { getTraining } from "sr/utils/api/cleaner/getTraining";
import { getPreferences } from "sr/utils/api/cleaner/getPreferences";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { FindCurrentEmployer } from "sr/helpers/functions/FindCurrentEmployer";
import { StringLoader } from "../component/Loader/StringLoader";
import { uploadFile } from "sr/utils/api/uploadFile";
import { toast } from "react-toastify";
import { getFiles } from "sr/utils/api/getFiles";
import { getFile } from "sr/utils/api/getFile";
import PhoneNumberFormat from "sr/helpers/functions/PhoneNumberFormat";
import { EmpoymentDuration } from "sr/helpers/functions/EmpoymentDuration";
import { DownloadResume } from "sr/helpers/functions/ApiFunction.tsx/DownloadResume";
import CleanerPersonalInfo from "./CleanerPersonalInfo";
import CleanerPersonalDetails  from "./component/CleanerPersonalDetails";
import { validateFile } from "sr/helpers/functions/validateFile";
import { error } from "console";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import { ModelWrapperWithButton } from "../company/component/ModelWrapperWithButton";
export default function CleanerProfileNew() {
  const [showModal, setShowModal] = React.useState(false);
  const [activeComponent, setActiveComponent] = useState<any>({});
  const [profileImg, setProfileImg] = useState(null) 
  const [cleanerId, setCleanerId] = useState()
  const [reference, setRefrence]  = useState<any>();
  const [employment, setEmployment] = useState<any>();
  const [jobType, setJobType] = useState<any>();
  const [training, setTraining] = useState<any>();
  const [medicalCondition, setMedicalCondition] = useState<any>();
  const [preference, setPreference] = useState<any>();
  const [popuptype, setpopupType] = useState('')
  const [id, setId] = useState('')
  const [resume, setResume] = useState()
  const [pdfData, setPdfData] = useState(null);
  const [cleanerData, setCleanerData] = useState<any>({});
  const [loader, setLoader] = useState('')
  const [resumeName, setResumeName] = useState('')
  const [currentEmployer, setCurrentEmployer] = useState(null)
  const [resumeKey, setResumeKey] = useState(null)
  const [experienceCount, setExperienceCount] = useState(null)
  const [fileerror, setFileError] = useState(null)
  const [showReplace, setShowReplace] = useState(false)
  const fetchReference = async (cleanerId) => {
    
    setLoader('Reference')
    const referenceData = await getReference(cleanerId, undefined);
    
    if (referenceData?.success) {
      setRefrence(referenceData.data);
      setLoader('')
    }
  };
  
  const fetchCleaner = async (cleanerId) => {
    setLoader('Profile')
    const cleanerData = await getCleaner(undefined, cleanerId);
    
    if (cleanerData?.success) {
      setCleanerData(cleanerData.data);
      setLoader('')
    }
  };
  
  const fetchEmploymentData = async (cleanerId) => {
    setLoader('Employment')
    const employmentData = await getEmployment(cleanerId, undefined);
    
    if (employmentData?.success) {
      setEmployment(employmentData?.data);
      const totalex = EmpoymentDuration(employmentData?.data)
      setExperienceCount(totalex)
      setCurrentEmployer(FindCurrentEmployer(employmentData.data))
      setLoader('')
    }
  };
  
  const fetchJobTypeData = async (cleanerId) => {
    setLoader('Job Type')
    const jobTypeData = await getJobType(cleanerId, undefined);
    
    if (jobTypeData?.success) {
      setJobType(jobTypeData.data);
      setLoader('')
    }
  };
  
  const fetchMedicalConditionData = async (cleanerId) => {
    setLoader('Medical Condition')
    const medicalConditionData = await getMedicalCondition(cleanerId, undefined);
    
    if (medicalConditionData?.success) {
      setMedicalCondition(medicalConditionData.data);
      setLoader('')
    }
  };
  
  const fetchTrainingData = async (cleanerId) => {
    setLoader('Training')
    const trainingData = await getTraining(cleanerId, undefined);
    
    if (trainingData?.success) {
      setTraining(trainingData.data);
      setLoader('')
    }
  };
  
  const fetchPreferenceData = async (cleanerId) => {
    setLoader('Preferences')
    const preferenceData = await getPreferences(cleanerId, undefined);
    
    if (preferenceData?.success) {
      setPreference(preferenceData.data);
      setLoader('')
    }}
    const fetchProfileImg = async (cleanerId)=> {
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      if(fileimg) {
      // const rawImageData = new Uint8Array(fileimg as ArrayBuffer);
      // const blob = new Blob([rawImageData], { type: 'image/png' });
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      }
      
    }}
    }

  const dataAddedHandle = (data)=> {
    if(data === true) {
      setShowModal(false)
      if(cleanerId) {
        switch(activeComponent.label) {
            case 'Reference':
              fetchReference(cleanerId)
              break;
            case 'Employment':
              fetchEmploymentData(cleanerId)
              break;

            case 'Job Type':    
              fetchJobTypeData(cleanerId)
              break;

            case 'Training':  
              fetchTrainingData(cleanerId)
              break;

            case 'Medical Condition': 
              fetchMedicalConditionData(cleanerId)
              break;

            case 'Preferences':
                fetchPreferenceData(cleanerId)
                break;
            case 'Personal Details':
                  fetchCleaner(cleanerId)
                  break;    
            case 'Personal Info':
                    fetchCleaner(cleanerId)
                    break;          
        }}
      setId('')
      setpopupType('')
    }
  }
  useEffect(() => {
    const getdetails = getUserDetails();
  
    if (getdetails) {
      setCleanerId(getdetails.user_id);
    }
  }, []);
  const fetchResumeFile = async () => {  
    setLoader('Resume') 
    const params = {
      cleaner_id:cleanerId
    }
const resumefile = await getFiles(params)
if(resumefile?.success===true) {
  console.log(resumefile?.data);
  const filterfile = resumefile?.data?.filter((item)=> item?.file?.fieldname==='resume')
  if(filterfile) {
setResumeName(filterfile[0]?.file?.originalname);
setResumeKey(filterfile[0]?.file?.key)
  }
setLoader('')
}
else {
  setLoader('')
}
  }

  const leftNav = [
    {
      label: 'Reference',
      componentName: CleanerReference
    },
    {
      label: 'Employment',
      componentName: CleanerEmployment
    },
    {
      label: 'Job Type',
      componentName: JobType
    },
    {
      label: 'Training',
      componentName: CleanerTraining
    },
    {
      label: 'Medical Condition',
      componentName: CleanerMedicalCondition
    },
    {
      label: 'Preferences',
      componentName: CleanerPreferences
    },

    {
      label:'Personal Info',
      componentName:CleanerPersonalInfo
    },
    {
      label:'Personal Details',
      componentName:CleanerPersonalDetails
    }

  ]

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
      
        //setLatLng(position.coords.latitude + "," + position.coords.longitude);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }, []);

  
  const openPopup = (index: any) => {  
    const activeComp = leftNav.filter(function (e, i) {
      
      return i === index
    });      
    setActiveComponent(activeComp[0]);
    
    setShowModal(true)
  };

  const scrollToSection = (id:any) => {
    if(id) {
    const section = document.getElementById('section-'+id);
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };}
  const onCheckModel = ()=> {
    setShowModal(false); 
    setId(''); 
    setpopupType('')
  }

const HandleImage = async (e:any)=> {
    const file: any = e.target.files[0]
    const formData = new FormData()
    formData.append('cleaner_id', cleanerId);
    formData.append('profile_img', file);
    const result = await uploadFile(formData);
    if(result?.success===true) {
      if(profileImg) {
      SuccessMessage("API.SUCCESS.PROFILE.IMG.UPDATE")
      } else {
        SuccessMessage("API.SUCCESS.PROFILE.IMG.UPLOAD")
      }
      fetchProfileImg(cleanerId)
    }
}
useEffect(()=> {
  if(cleanerId) {
  fetchResumeFile()
  }
},[cleanerId])
useEffect(() => {
  const fetchData = async () => {
    if (cleanerId) {
      await fetchCleaner(cleanerId);
      await fetchReference(cleanerId);
      await fetchEmploymentData(cleanerId);
      await fetchJobTypeData(cleanerId);
      await fetchTrainingData(cleanerId);
      await fetchMedicalConditionData(cleanerId);
      await fetchPreferenceData(cleanerId);
      await fetchProfileImg(cleanerId)
    }
  };

  fetchData();
}, [cleanerId]);

  const childRef = useRef(null);

  const handleButtonClick = () => {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm()
    }
  };
const HandleResume = async (e: any) => {
  try {
  const file = e.target.files[0];
  const check = validateFile(file)
  setFileError('')
  if(check !== true) {
    setFileError(check)
    return 
  }
  setLoader('Fileupload')
  const data = new FormData();
  data.append('cleaner_id', cleanerId);
data.append('resume', e.target.files[0]);
const result = await uploadFile(data);
if(result.success===true) {
  setLoader('')
  SuccessMessage("API.SUCCESS.RESUME.UPLOAD")
  fetchResumeFile()
}
  }
  catch (error) {
    setLoader('')
    console.error('Error uploading resume:', error);
  }
};
  return (
    <>
      <AuthNavComponent isLoggedin={true}></AuthNavComponent>
     
      <main className="lg:container lg:flex block mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
        <div className="w-full">
          <ContentWithSpinner loader={loader==='Profile'}>
          <div className="border border-gray-border rounded-xl flex w-full bg-white text-black pt-10 pb-10">
            <div className="w-full lg:pl-20">
              <div className="content-center lg:float-left float-center">
              <div className="radius-50 container mx-auto my-auto flex justify-center w-[200px] pt-4">
  <label htmlFor="imgInput" className="relative">
    <img alt="Profile" className={`rounded-full w-40 h-40 cursor-pointer`} onMouseEnter={() => setShowReplace(true)}
        src={` ${profileImg ? profileImg: "/media/icons/displayProfilePlaceholder.png"}`}/>
   {showReplace && 
     <div onMouseLeave={()=> setShowReplace(false)} className="rounded-full w-40 h-40 absolute top-0 bg-[#00000099] inset-auto flex justify-start items-center">
    <div>
      <div className="flex justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
</svg></div>
<p className="font-satoshiBold text-white text-3xl text-center">Replace photo</p>
</div> 
     </div>
}
  </label>
  <input onChange={HandleImage} className="hidden" type="file" id="imgInput" name="img" accept="image/*"></input>
</div>
              </div>
              <div className="lg:float-left float-none text-center lg:pl-10 pt-2 pb-2 lg:text-left">
                <div className="border-b-2 border-gray-300 w-100 pb-3">
                  <div className="flex justify-start space-x-3 items-center">
                  <div className="font-bold text-[24px]">
                    {cleanerData?.first_name} {cleanerData?.last_name}
                  </div>
                  <svg onClick={() => 
  {openPopup(7);
  setpopupType('put');
  setId(cleanerData?.id)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
</svg>

                  </div>
                  <ContentWithLoader content={<StringLoader />} loading={currentEmployer === null}>
                  <div className="font-700 text-[16px]">
                    {currentEmployer?.job_title}
                  </div>
                  </ContentWithLoader>
                  <ContentWithLoader content={<StringLoader />} loading={currentEmployer===null}>
                  <div className="font-500 text-[14px]">
                     {currentEmployer?.company_name && 'at ' + currentEmployer?.company_name}
                  </div>
                  </ContentWithLoader>
                </div>
                <div className="mt-4">
                  <div className="border-r-2 border-gray-300  text-gray-800 lg:float-left lg:pr-12">
                  <div className="flex justify-start space-x-3 items-center">
                    <div className="mt-2 text-[14px]">
                      <FontAwesomeIcon icon={faMapMarker} className='pl-1 pr-3 cursor-pointer' />
                      
                      {cleanerData?.address?.address_line_1} {cleanerData?.address?.address_line_2} {cleanerData?.address?.city}  {cleanerData?.address?.state}
                    </div>
                    <svg onClick={() => 
  {openPopup(6);
  setpopupType('put');
  setId(cleanerData?.id)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
</svg></div>

                    <div className="mt-3 font-700 text-[16px]">
                      <FontAwesomeIcon icon={faBriefcase} className='pr-1' /> {experienceCount && `${experienceCount?.totalYears} Years ${experienceCount?.totalMonths} Months`}
                    </div>
                    
                  </div>
                  <div className="text-gray-800 lg:float-left lg:pl-12 pl-0">
                    <div className="mt-2 text-[14px]">
                      <FontAwesomeIcon icon={faPhone} className='pr-3' />
                      {PhoneNumberFormat(cleanerData?.mobile_number)}
                    </div>
                    <div className="mt-3 font-700 text-[16px]">
                      <FontAwesomeIcon icon={faEnvelope} className='pr-1' /> {cleanerData?.email}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          </ContentWithSpinner>
          <div className="mt-8 rounded-xl w-full text-black  pb-10 lg:flex block">
            <div className="h-full border border-gray-border  rounded-xl lg:w-1/4 w-full bg-white text-black pt-10 p-10 float-left">
              <div className="w-full text-[18px] font-[700]">
              <FormattedMessage 
  id='CLEANER.PROFILE.QUICKLINKS.TITLE'
  defaultMessage='Quick Links'
/>
              </div>
              <div className="w-full flex">
                <ul className="w-full">
                  {
                    leftNav?.slice(0, 6)?.map((item: any, i: any) => 
                      (
                        <li key={i} className="bg-white w-full hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 float-left hover:border border-gray-400 hover:rounded hover:shadow mt-2" onClick={()=>scrollToSection(i)}>
                          <div className="float-left">{item.label}</div>
                          <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={()=>openPopup(i)}>
                          <FormattedMessage 
  id='CLEANER.PROFILE.ADD.BUTTON'
  defaultMessage='Add'
/>
                          </div>
                        </li>
                      )
                    )
                  }
                </ul>
              </div>
            </div>

            <div className="lg:w-3/4 w-full text-black float-left lg:ml-10 mt-8 lg:mt-0 ml-0">
            <ContentWithSpinner loader={loader==='Resume'}>
              <div className="border border-gray-border  rounded-xl w-full bg-white text-black p-10">
                <div className="w-full text-[18px] font-[700] inline-block">
                <div className="float-left">
                <FormattedMessage 
  id='CLEANER.PROFILE.RESUME.TITLE'
  defaultMessage='Resume'
/>

</div>
                </div>
             
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage 
  id='CLEANER.PROFILE.RESUME.TEXT'
  defaultMessage='Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.'
/>   
{    resumeName &&  
<div className="flex items-center justify-between  w-full my-4 px-5">
  <p className="font-satoshiBold">{resumeName} </p>
<svg onClick={()=> DownloadResume(resumeName, resumeKey)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>

</div>
}
<p className="text-red-800">{fileerror && fileerror}</p>
<div className="flex items-center justify-center w-full mt-2">
    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
       <ContentWithSpinner loader={loader==='Fileupload'}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">Supported Formats: doc, docx, rtf, pdf, upto 2 MB
</p>
        </div>
        <input onChange={HandleResume} id="dropzone-file" type="file" className="hidden" />
        </ContentWithSpinner>
    </label>
</div> 

        </div>
      

              </div>
              </ContentWithSpinner>
{/* 

              <div id="section-0" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left"><FormattedMessage 
  id='CLEANER.PROFILE.DOCUMENT.TITLE'
  defaultMessage='Documents'
/></div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(0)}>                <FormattedMessage 
  id='CLEANER.PROFILE.ADD.DOCUMENT.TITLE'
  defaultMessage='Add Document'
/> </div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage 
  id='CLEANER.PROFILE.DOCUMENT.TEXT'
  defaultMessage='Documents help recruiters know about your identity'
/>


                </div>
                <div className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]">
<FormattedMessage 
  id='CLEANER.PROFILE.DOCUMENT.UPLOADIDPROOF'
  defaultMessage='Upload Id Proof'
/>
 <FontAwesomeIcon icon={faFile} className='pl-3 pr-3 text-blue-800 cursor-pointer' onClick={() => openPopup(0)} /> <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => setShowModal(true)} /></div>
                </div>
                <div className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]"><FormattedMessage 
  id='CLEANER.PROFILE.DOCUMENT.ADDRESSPROOF'
  defaultMessage='Address Proof'
/> <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer text-blue-800 cursor-pointer' onClick={() => openPopup(0)} /></div>
                </div>
              </div>               */}
            <ContentWithSpinner loader={loader === 'Reference'}>
              <div id="section-1" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left">
                    <FormattedMessage 
  id='CLEANER.PROFILE.REFERENCE.TITLE'
  defaultMessage='Reference'
/></div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(0)}>
                  <FormattedMessage 
  id='CLEANER.PROFILE.ADDREFERENCE.TITLE'
  defaultMessage='Add Reference'
/>

                  </div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage 
  id='CLEANER.PROFILE.REFERENCE.TEXT'
  defaultMessage='Reference information helps recuiters know about your referral person'
/>
                </div>
                {reference?.map((data, index)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]">{data?.first_name} {data?.last_name} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => {
  openPopup(0);
  setpopupType('put');
  setId(data?.id)
}}
/></div>
                  <div className="text-[14px] font-[500] mt-1">{data?.email}</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1">{PhoneNumberFormat(data?.mobile_number)}</div>
                </div>
                ))}
              </div>
              </ContentWithSpinner>
              <ContentWithSpinner loader={loader === 'Employment'}>
              <div id="section-2" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left"> 
                  <FormattedMessage 
  id='CLEANER.PROFILE.EMLOYMENT.TITLE'
  defaultMessage='Employment'
/></div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(1)}> <FormattedMessage 
  id='CLEANER.PROFILE.ADDEMLOYMENT.TITLE'
  defaultMessage='Add Employment'
/></div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage 
  id='CLEANER.PROFILE.EMLOYMENT.TEXT'
  defaultMessage='Employment details helps recuiters understand your background'
/>
                </div>
                {employment?.map((data, index)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]">{data?.job_title} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => 
  {openPopup(1);
  setpopupType('put');
  setId(data?.id)}} /></div>
                  <div className="text-[14px] font-[500] mt-1">{data?.company_name}</div>
                  <div className="text-[14px] font-[500] mt-1">{data?.company_location}</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1">{data?.job_type}  {data?.working_since_start && data?.working_since_end ? DateFormat(data?.working_since_start) +'-'+ DateFormat(data?.working_since_end) : 'Present ' + DateFormat(data?.working_since_start)}</div>
                </div>
                ))}
              </div>
              </ContentWithSpinner>

              <ContentWithSpinner loader={loader === 'Job Type'}>
              <div id="section-3" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left">  <FormattedMessage 
  id='CLEANER.PROFILE.JOBTYPE.TITLE'
  defaultMessage='Job Type'
/> </div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(2)}>
                  <FormattedMessage 
  id='CLEANER.PROFILE.ADDJOBTYPE.TITLE'
  defaultMessage='Add Job Type'
/>
                  </div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.JOBTYPE.TEXT" defaultMessage="Job Type details helps recuiters understand your Job Type" /></h3>
                </div>
                {jobType?.map((data,index)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]">{data?.job_type} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() =>  {openPopup(2);
  setpopupType('put');
  setId(data?.id)}} /></div>
                  <div className="text-[14px] font-[500] mt-1"> <FormattedMessage id="CLEANER.PROFILE.JOBTYPE.RATE" defaultMessage="Hourly Rate" />  - ${data?.rate}</div>
                  <div className="text-[14px] font-[500] mt-1"> <FormattedMessage id="CLEANER.PROFILE.JOBTYPE.ADDITIONALINFORMATION" defaultMessage="Additional Information" /> {data?.additional_information}</div>
                </div>
               ))}
              </div>
              </ContentWithSpinner>
              <ContentWithSpinner loader={loader === 'Training'}>
              <div id="section-4" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left">
                    <FormattedMessage id="CLEANER.PROFILE.TRAINING.TITLE" defaultMessage="Training Name" />
                      </div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(3)}> <FormattedMessage id="CLEANER.PROFILE.ADD.TRAINING" defaultMessage="Add Training" /></div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage id="CLEANER.PROFILE.TRAINING.TEXT" defaultMessage="Training details helps recuiters understand your recent training" />
                </div>
                {training?.map((data, index)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]">{data?.training} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => 
  {openPopup(3);
  setpopupType('put');
  setId(data?.id)}}/></div>
                  <div className="text-[14px] font-[500] mt-1"> <FormattedMessage id="CLEANER.PROFILE.TRAINING.DATE.ATTENDED" defaultMessage="Date Attended" /> - {DateFormat(data?.date_attended)}
</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1"><FormattedMessage id="CLEANER.PROFILE.TRAINING.INPUT.DESCRIPTION.TITLE" defaultMessage="Description" /> - {data?.description}</div>
                </div>
))}
              </div>
              </ContentWithSpinner>
              <ContentWithSpinner loader={loader === 'Medical Condition'}>
              <div id="section-5" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left"> <FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.TITLE" defaultMessage="Medical Condition" />  </div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(4)}><FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.ADD.TITLE" defaultMessage="Add Medical Condition" /></div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.TEXT" defaultMessage="Medical condition details helps recuiters understand your medical conditions" />
                </div>
                {medicalCondition?.map((data:any, index:any)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[16px] font-[700]"> <FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.INPUT.CONDITION" defaultMessage="Condition" /> - {data?.condition} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => 
  {openPopup(4);
  setpopupType('put');
  setId(data?.id)}} /></div>
                  <div className="text-[14px] font-[500] mt-1"> <FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.SINCE.WHEN" defaultMessage="Since When" /> - {DateFormat(data?.since_when)}</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1"> <FormattedMessage id="CLEANER.PROFILE.MEDICALCONDITION.DESCRIPTION" defaultMessage="Description" /> - {data?.description}</div>
                </div> 
 ))}              
              </div>
              </ContentWithSpinner>
              <ContentWithSpinner loader={loader === 'Preferences'}>
              <div id="section-6" className="border border-gray-border  rounded-[18px] w-full bg-white text-black pl-10 pr-10 pt-5 pb-10 mt-8 inline-block">
                <div className="w-full inline-block font-bold  text-[18px]">
                  <div className="float-left"> <FormattedMessage id="CLEANER.PROFILE.PREFERENCES.TITLE" defaultMessage="Preferences" /> </div>
                  <div className="float-right pl-10 text-blue-800 cursor-pointer" onClick={() => openPopup(5)}> <FormattedMessage id="CLEANER.PROFILE.ADD.PREFERENCES" defaultMessage="Add Preferences" /></div>
                </div>
                <div className="w-full text-[14px] font-[500]">
                <FormattedMessage id="CLEANER.PROFILE.PREFERENCES.TEXT" defaultMessage="preferences details helps recuiters understand your preferences" />
                </div>
                {preference?.map((data:any, index:any)=> (
                <div key={index} className="w-full mt-5 inline-block">
                  <div className="text-[14px] font-[500] mt-1"><FormattedMessage id="INPUT.RADIUS.TITLE" defaultMessage="Radius (in Miles)" /> - {data?.radius} <FontAwesomeIcon icon={faPencil} className='pl-1 pr-3 cursor-pointer' onClick={() => 
  {openPopup(5);
  setpopupType('put');
  setId(data?.id)}} /></div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1">Shift Type -  {data?.shift_type}</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1"><FormattedMessage id="CLEANER.PROFILE.PREFERENCES.MINWORKHOURS" defaultMessage="Min hours Work(per week)" /> - {data?.min_hours}</div>
                  <div className="text-[14px] font-[500] text-gray-800 mt-1"><FormattedMessage id="CLEANER.PROFILE.PREFERENCES.MAXWORKHOURS" defaultMessage="Max hours Work(peer week)" /> - {data?.max_hours}</div>
                </div>                
))}
              </div>
              </ContentWithSpinner>
            </div>
          </div>
        </div>

        {/* modal popup */}

<ModelWrapperWithButton Save={handleButtonClick} headertitle={`Add ${activeComponent.label}`} showModal = {showModal} onCheckModel={onCheckModel}>
{popuptype ==='put' ? id &&( <activeComponent.componentName page={'popup'}  type={'put'} id={id} ref={childRef} dataAddedHandle={dataAddedHandle}/>): <activeComponent.componentName page={'popup'} type={'add'} ref={childRef} dataAddedHandle={dataAddedHandle}/>}
</ModelWrapperWithButton>
      </main>
   
      <Footer />
    </>
  )
}