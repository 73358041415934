import React from 'react'

export const Profile = () => {
    const categoryData = [
        { CategoryName: 'Dusting', CategoryCount: 500 },
        { CategoryName: 'Mopping', CategoryCount: 300 },
        { CategoryName: 'Sweeping', CategoryCount: 200 },
        { CategoryName: 'Vacuuming', CategoryCount: 100 },
      ];  
  return (
    <div className='z-10 relative lg:flex block gap-4 items-center'>
    <div className='w-40 h-40'>
        <div className='transform lg:transform-none -translate-x-1/2'>
        <img alt="Profile" className="rounded" src="/media/icons/displayProfilePlaceholder.png" />
    </div></div>
    <div className='inline-block'>
        <div className='lg:flex block text-center items-center gap-4'>
            <div className='font-bold font-satoshiBold text-xl'>
            Ritik Sharma
            </div>
            <div className="items-center flex justify-center divide-x divide-solid divide-slate-400 gap-1">
            <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#EAB308" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#EAB308]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>                <div className="text-xs text-gray-500 font-satoshiBold font-bold">3.8</div></div>

            <div className="text-xs	 text-gray-500 font-satoshiBold font-bold pl-1">
                (101 Reviews)
            </div>
            </div>

            </div>
            <div className="flex my-2 gap-2 flex-wrap">
                {categoryData.map((data)=> (
            <div className="inline-block w-auto font-semibold text-xs text-gray-900 border border-gray-300 py-1 px-2 rounded-3xl">{data.CategoryName}</div>
       )) }
            </div>


</div>
<div>
   </div>
</div>
  )
}
