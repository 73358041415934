import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
export const WorkOrderLeftSidebar = ({candidates, total, currentPage, allApplicant, setCleanerId}) => {
  const [activeId, setActiveId] = useState('')
  const params = useParams()
  useEffect(()=> {
    const {id} = params
    setActiveId(id)
  },[params])
  return (
    <>
    <div className='border-neutral-200 border-b py-4'>
    <h2 className='px-4 text-2xl font-satoshiBold text-[#595959]'><FormattedMessage id='RECRUITER.POSTED.JOB.CANDIDATES.TITLE' defaultMessage='Candidates' /> ({total && total})</h2>
    <p className='px-4 text-xs text-[#595959]'><FormattedMessage id='RECRUITER.CANDIDATES.PAGEINATION.TITLE' defaultMessage='Candidate {activeNumber} of {totalNumber}' values={{activeNumber:currentPage+1, totalNumber:allApplicant?.length}}/></p>
    </div>
   { candidates?.map((data, index)=> (

    <div onClick={()=> setCleanerId(data?.cleaner_id?._id)} key={index} className={`border-neutral-200 border-b py-4 cursor-pointer ${data?.id=== activeId ? 'bg-[#FAF9F8]': ''}`}>
    <h2 className='px-4 text-base font-satoshiBold'>{data?.cleaner_id?.first_name} {data?.cleaner_id?.last_name}</h2>
    <p className='px-4 text-xs text-[#595959]'>{data?.cleaner_id?.address?.address_line_1} {data?.cleaner_id?.address?.address_line_2}</p>
    </div>
    )) }
  </>)

}
