import React, { useState } from 'react'
import AutoLocation from 'sr/helpers/ui-components/AutoLocation'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
export const LocationAdd = () => {
    const [addresstype, setAddressType] = useState('')
    const [address, setAddress] = useState({})
    const intl = useIntl()
const HandleAutoLocation = (data)=> {
    setAddress(data)
}
  return (
    <form>
                <AutoLocation HandleAutoLocation={HandleAutoLocation}></AutoLocation>  
              
<DropDown dropdowndiv=' mt-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.DROPDOWN.TYPE" defaultMessage="Address Type" />} value={addresstype} onChange={(e) => setAddressType(e.target.value)} className=' '>
                    <option value=''>{<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.DROPDOWN.SELECT" defaultMessage="Select Type" />}</option>
                    <option value='Residencial'>Residencial</option>
                    <option value='Commercial'>Commercial</option>
                </DropDown>
                <Input InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFROOMS" defaultMessage="No of Rooms" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFROOMS", defaultMessage:"No of Rooms" }) }
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS" defaultMessage="No of Bathrooms" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS", defaultMessage:"No of Bathrooms" })}
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.TOTALAREA" defaultMessage="Total Area" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS.PLACEHOLDER", defaultMessage:"Square Feet" })}
                    type={'text'}
                    inputClass = ''>
                </Input>   
                <div className='mt-10'>
                <label htmlFor="Remarks" className=" mb-2 text-sm font-satoshiBold flex items-center"><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.REMARKS" defaultMessage="Remarks" /></label>
<textarea id="Remarks" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({id:'INDIVIDUAL.MANAGE.ADDRESSES.INPUT.REMARKS.PLACEHOLDER', defaultMessage:'Write your note here...'})}></textarea>        
</div>              
                </form>
  )
}
