import DashboardWrapper from 'app/pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from 'app/pages/layout-builder/BuilderPageWrapper'
import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from 'sr/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { Jobs } from 'app/pages/individual/jobs/jobs'
import { WorkOrder } from 'app/pages/company/work-order/WorkOrder'
import { ScheduleWorkOrder } from 'app/pages/company/work-order/ScheduleWorkOrder'
import { AddCustomer } from 'app/pages/company/Customer/AddCustomer'
import { ManageCustomer } from 'app/pages/company/Customer/ManageCustomer'
import { CustomerDetails } from 'app/pages/company/Customer/CustomerDetails'
import { useAuth } from 'app/pages/module/auth'
import CleanerProfileNew from 'app/pages/cleaner/CleanerProfileNew'
import { AppliedWorkOrder } from 'app/pages/jobs/Myjobs/AppliedWorkOrder'
import { SupervisorDetails } from 'app/pages/company/work-order/supervisor/SupervisorDetails'
import { IndividualJobPost } from 'app/pages/individual/jobs/job-post'
import { LocationManage } from 'app/pages/individual/basicdetails/LocationManage'
import { ChecklistManage } from 'app/pages/individual/basicdetails/ChecklistManage'
import CleanerProfile from 'app/pages/cleaner/CleanerProfile'
import { JobPostNew } from 'app/pages/individual/jobs/JobPostNew'
import { AccountSetting } from 'app/pages/individual/AccountSetting/AccountSetting'
import { JobPostDetails } from 'app/pages/individual/jobs/JobPostDetails'
import { CleanerProfiles } from 'app/pages/individual/jobs/CleanerProfiles'
import { Confirm } from 'app/pages/individual/jobs/Confirm'
import { ConfirmAndBook } from 'app/pages/individual/jobs/ConfirmAndBook/ConfirmAndBook'
import { PendingDetails } from 'app/pages/supervisor/PendingDetails'
import { SavedWorkOrder } from 'app/pages/jobs/workorder/SavedWorkOrder'
import { Contractor } from 'app/pages/company/work-order/contractor/Contractor'
import { AddContractor } from 'app/pages/company/work-order/contractor/AddContractor'
import { ContractorDetails } from 'app/pages/company/work-order/contractor/ContractorDetails'
import { CompanyRoutes } from './component/CompanyRoutes'
import { SupervisorRoutes } from './component/SupervisorRoutes'
type WithChildren = {
  children: any
}

const PrivateRoutes = () => {
  // const UploadModule = lazy(() => import('../modules/profile/ProfilePage'))
  const {isAuthenticate, isAuthReady, logout, userType} = useAuth()
  return (
    <Routes>
      <Route>
    
        {userType === 'company' &&
        <>
{CompanyRoutes?.map((data, index) => (
                <Route key={index} path={data.path} element={<data.element />} />
            ))}
              <Route path='/*' element={<Navigate to='/jobs-listing' />} />
              </>
}
              
         {  userType === 'cleaner' && <>
              <Route path='/cleaner-profile-page' element={<CleanerProfileNew />} />
              <Route path='/cleaner/applied-work-order' element={<AppliedWorkOrder />} />
              <Route path='/saved-work-order' element={<SavedWorkOrder />} />
              <Route path='/*' element={<Navigate to='/cleaner-jobs' />} />
              </>
}
{userType=== 'supervisor' && 
<Route path='/*' element={<SupervisorRoutes />} />}

{
  userType==='individual' && <>
             <Route path='/individual/jobs' element={<Jobs />} />
              <Route path='/individual/job-post' element={<IndividualJobPost />} />
              <Route path='/individual/location' element={<LocationManage />} />
              <Route path='/individual/tasklist' element={<ChecklistManage />} />
              <Route path='/individual/cleaner-profile' element={<CleanerProfile />} />
              <Route path='/individual/job-post-new' element={<JobPostNew />} />
              <Route path='/individual/job-post-details' element={<JobPostDetails />} />
              <Route path='/individual/Account' element={<AccountSetting />} />
              <Route path='/individual/cleaner-profiles' element={<CleanerProfiles />} />
              <Route path='/individual/confirm' element={<Confirm />} />
              <Route path='/individual/confirm-and-book' element={<ConfirmAndBook />} />
              <Route path='/*' element={<Navigate to='/individual/jobs' />} />
  </>
}

      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  TopBarProgress.config({
    barColors: {
      '0': '#000',
    },
    barThickness: 4,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
