import React, { useEffect, useRef, useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { Filter } from "../component/Filter";
import { Checkbox } from "sr/helpers";
import { Link, useParams } from "react-router-dom";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { CandidateStatus, usaCities } from "sr/i18n/messages/setting";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { updateContractor } from "sr/utils/api/company/workorder/updateContractor";
import { fetchData } from "pdfjs-dist";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import { WhiteBtn } from "../component/WhiteBtn";
import { useAuth } from "app/pages/module/auth";
import { Pageination } from "sr/helpers/ui-components/Pageination";
import { Pagination } from "app/pages/component/Pagination";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import Form from "./components/Form";

export const Contractor = () => {
    const intl = useIntl()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [location, setLocation] = useState()
    const [data, setdata] = useState([])
    const [userId, setuserId] = useState<string>()
    const [loader, setLoader] = useState(true)
    const [totalPage, settotalPage] = useState<number>(0)
    const {userType} = useAuth()
    const dispatch = useDispatch<AppDispatch>()
    const [modalShow, setShowModal] = useState(false)
    const [id, setId] = useState<string>()
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    const [customerLocationId, setCustomerLocationId] = useState<any>()
    const childRef:any = useRef()
    useEffect(()=> {
            const {user_id} = getUserDetails()
            setuserId(user_id)
    },[])
    useEffect(()=> {
      if(userId && userType==='supervisor') {
        if(!supervisorData){
          dispatch(fetchSupervisor({id:userId}))
        }
      }
},[userId, userType, dispatch])
const fetchAllLocation = async (companyId)=> {
  const data= await fetchCustomerLocations({userType:userType, companyId:companyId})
  setCustomerLocationId(data?.map((item)=> item?.id)?.join())
}
  
useEffect(()=> {
  if(supervisorData){
  const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
  if(id?.length>0){
  setCustomerLocationId(id?.join());
  }
  else {
  fetchAllLocation(supervisorData?.company_id?._id)
}
}
  
 },[supervisorData])
    const loadContractor = async ()=> {
          await fetchContractor({userType:userType, userId:userId, supervisor_id:supervisorData?.id, customerLocationId:customerLocationId, setData:setdata, setLoader:setLoader, page:page, limit:limit})
    }
    useEffect(()=> {
      if(userId && userType) {
        if(userType==='supervisor'){
          if(customerLocationId){
      loadContractor()
      }
    }
    else {
      loadContractor()
    }}
    },[userId, customerLocationId, userType])

        const HandleDeleteContractor = async (id)=> {
           const payLoad= {   
            id:id,
            status:'deleted'
           }
           const res = await updateContractor(payLoad)
           if(res?.success===true){
            SuccessMessage('API.SUCCESS.CONTRACTOR.DELETE')
            setdata(data?.filter((item)=> item?.id !== res?.data?.id))
           }
        }
        const HandleEdit = (id)=> {
            setShowModal(true)
            setId(id)
        }
        const onCheckModel = ()=> {
          setShowModal(false)
          setId('')
        }
        const HandleEditSave = ()=> {
          if (childRef.current && childRef.current.submitForm) {
            childRef.current.submitForm()
          }
        }
        const dataAddedHandle = (data)=> {
          if(data === true) {
            setShowModal(false)
            loadContractor()
          }
        }
  return (
    
    <MainWrapper>
    <div className="py-3">
      <ContentWithSpinner  loader={loader} height="h-screen">
        <div className='mb-2'>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.SIDEBAR.MENU.CONTRACTOR" defaultMessage="Contractors" /></h1>
    <p><FormattedMessage id="RECRUITER.CONTRACTOR.TEXT" defaultMessage="Use this page to manage your contractor" /></p>
   </div>
   <div className='flex justify-between'>
        <div className='flex w-1/6 space-x-4'>
        <DropDown labelClass='text-base' value={location} label={intl.formatMessage({
    id: 'RECRUITER.WORKORDER.FILTER.LOCATION',
    defaultMessage: 'Location',
  })}
 dropdowndiv='mt-1' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION" defaultMessage="Select Location" /></option>
    {usaCities?.map((item, index)=> (
    <option key={index} value={item?.value}>{item?.value}</option>
  ))
}
  </DropDown>
</div>
{((userType==='company') || (userType==='supervisor' && supervisorData?.access?.contractor_management?.add_contractor)) &&
  <div className='flex'>
    <Link to="/add-contractor">
  <WhiteBtn mainClass="mt-5"  title={<FormattedMessage id="RECRUITER.WORK.ORDER.CONTRACTOR.ADD.BTN." defaultMessage="Add Contractor" />}></WhiteBtn>
  </Link>

  </div>
}
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.NAME" defaultMessage="Name" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.MOBILENO" defaultMessage="Mobile No" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.ADDRESS" defaultMessage="Address" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.STATUS" defaultMessage="Status" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.ACTION" defaultMessage="Action" />
                </th>
            </tr>
        </thead>
        <tbody>
          {data?.map((item, index)=> (
         <tr className="bg-white border-b  hover:bg-gray-50">
         <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
       <Link to={`/contractor-details/${item?.id}`}>
             <div className='text-base cursor-pointer'>{item?.first_name} {item?.last_name}</div> 
             </Link>
         </th>
         <td className="px-6 py-4">
           {item?.mobile_number}
         </td>
         <td className="px-6 py-4">
             {item?.address?.address_line_1} {item?.address?.address_line_2} {item?.address?.city}
         </td>
         <td className="px-6 py-4">
        {getLabelByValue(item?.status, CandidateStatus)}
         </td>
        
         <td className="px-6 py-4">
        
         <div className='flex items-center space-x-4 cursor-pointer'>
          {((userType==='company')|| (userType==='supervisor' && supervisorData?.access?.contractor_management?.update_contractor)) &&
         <div onClick={()=> HandleEdit(item?.id)} className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
</svg>

</div>
}
{((userType==='company')|| (userType==='supervisor' && supervisorData?.access?.contractor_management?.delete_contractor)) &&
            <div onClick={()=> HandleDeleteContractor(item?.id)} className='p-2 group cursor-pointer relative rounded-lg inline-flex divide-x w-auto border border-neutral-200'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
              </svg>
              <span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
                <FormattedMessage
                  id='RECRUITER.CONTRACTOR.TABLE.COL.ACTION.DELETE'
                  defaultMessage='Delete Contractor'
                />
              </span>
          </div>
}   
          </div>
        </td>

     </tr>
))}
          
        </tbody>
    </table>
    <Pagination page={page} totalPage={totalPage} setPage={setPage} />
    </div>
</ContentWithSpinner>
   </div>
   <ModelWrapperWithButton showModal={modalShow} onCheckModel={onCheckModel} Save={HandleEditSave}>
   <Form type='popup' id={id} ref={childRef} dataAddedHandle={dataAddedHandle}/>
   </ModelWrapperWithButton>
   </MainWrapper>
   );
};
