import React, {useEffect, useState} from 'react'
import AuthNavComponent from './AuthNav'
import { Input, toAbsoluteUrl } from 'sr/helpers'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { FormDetails } from 'sr/helpers/ui-components/FormDetails';
import { useSelector } from 'react-redux';
import { RootState } from 'sr/redux/reducers';
import { useFormik } from 'formik';
import { verifyOTP } from 'sr/utils/api/verifyOTP';
import * as Yup from 'yup'
export default function VerifyOTP() {
    const [registeredData, setRegisteredData] = useState(null)
    const registeredCompanyData = useSelector((state: RootState) => state.registerCompany);
    const registeredCleanerData = useSelector((state: RootState) => state.registerCleaner);
    useEffect(()=> {
    if(localStorage.getItem('RegisterAs') === 'Company') {
        
        setRegisteredData(registeredCompanyData)
    }
    else if(localStorage.getItem('RegisterAs') === 'Individual') {
        // const registeredCleanerData = useSelector((state: RootState) => state.registerCleaner);
    }
    else {
        
        setRegisteredData(registeredCleanerData)
    }
},[])
    
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = Array.from({ length: 4 }, () => React.createRef());
  useEffect(()=> {
console.log(otp);

  },[otp])
  const handleInputChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
  };

const initialValues = {
    username:registeredData?.username,
    otp:otp.join('')
}
const {errors, submitForm} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validationSchema:Yup.object({
        otp:Yup.string().required("Please Enter 4 Digit Verification Code")
    }),
    onSubmit:async (values)=> {
const result = await verifyOTP(values)
if(result.success ===true) {
        if(localStorage.getItem('RegisterAs') === 'Company') {
            navigate('/company-profile')
        }
        else if(localStorage.getItem('RegisterAs') === 'Individual') {
            navigate('/individual/jobs')
        }
        else if(localStorage.getItem('RegisterAs')==='supervisor')
        {
            navigate('/supervisor/work-order')
        }
        else {
            const result = await verifyOTP(values)
            if(result.success === true) {
             navigate('/cleaner-profile')
            }
        }
    }
    }
})

    return (
        <>
            <AuthNavComponent></AuthNavComponent>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 lg:flex block'>
                    <FormDetails />
                    <div className='lg:flex-1 lg:flex block flex-col lg:mt-0 mt-4 h-fit lg:mx-12 mx-auto p-6 bg-white border border-gray-border rounded-xl'>
                        <h1 className='font-satoshiBold text-xl'>Verify email address</h1>
                        <div className='max-w-lg'>
                            <form className='mt-5'>
                                <label className={`text-normal font-satoshiBold text-sm`}>
                                    Please enter the verification code sent to your email address {registeredData?.email}
                                    <FontAwesomeIcon icon={faPen} className='ml-2 text-gray-800 cursor-pointer' />
                                </label>
                                <div className='flex'>
      {otp.map((digit, index) => (
        <div className='w-16 mr-5' key={index}>
          <Input
          errors
            type="tel"
            minLength="1"
            maxLength="1"
            inputClass="text-center"
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            ref={inputRefs[index]}
          />
        </div>
      ))}
    </div>
                                <div className='mt-2'>
                                    <label className={`text-normal font-Satoshi text-xs text-gray-400`}>
                                        Your verification code should arrive in 42 seconds
                                    </label>
                                </div>
                            </form>
                            <div className='mt-5 flex space-x-10'>
                                <button type='submit'
                                    className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                                onClick={submitForm}>Verify</button>
                          <button type='submit'
                                    className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                                onClick={() => { localStorage.getItem('RegisterAs') === 'Company' ? navigate('/company-profile') : localStorage.getItem('RegisterAs') === 'Individual' ? navigate('/individual/jobs'): localStorage.getItem('RegisterAs')==='supervisor' ? navigate('/supervisor/work-order'):  navigate('/cleaner-profile')}}>Skip</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}