import React, {useState} from 'react'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
export const TaskForm = () => {
    const [selectType, setselectType] = useState()
const intl = useIntl()
  return (
    <div>
 <Input InputDivClass="mt-2" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.CUSTOMER.INPUT.CHECKLIST.TASK.TITLE" defaultMessage="Task" />}
                    placeholder={''}
                    type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'>
                </Input>
                <div className='mt-10'>
                <label htmlFor="Description" className=" mb-2 text-sm font-satoshiBold flex items-center"><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.CUSTOMER.INPUT.CHECKLIST.DESCRIPTION.TITLE" defaultMessage="Description" /></label>
<textarea id="Description" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({id:'INDIVIDUAL.MANAGE.TASKLIST.INPUT.CHECKLIST.DESCRIPTION.PLACEHOLDER', defaultMessage:'Write your Description here...'})}></textarea>        
</div>
                <DropDown dropdowndiv='text-gray-700 mt-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TYPE.TITLE" defaultMessage="Type" />}value={selectType} onChange={(e) => setselectType(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                    <option value=''>Select Type</option>
                    <option value='Mandatory'>Mandatory</option>
                    <option value='Optional'>Optional</option>
                </DropDown>
    </div>
  )
}
