import React, {useState} from 'react'
import { Input } from 'sr/helpers';
import DropDown from 'sr/helpers/ui-components/DropDown.tsx';
export const LocationEdit = () => {
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [addresstype, setAddressType] = useState('')
  return (
    <div>
    <form>
      <Input
        InputDivClass="mt-1"
        label={"Street"}
        placeholder={"Enter street"}
        type={"text"}
        value={streetAddress}
        onChange={(e:any) => setStreetAddress(e.target.value)}
      ></Input>

      <Input
        label={"City"}
        placeholder={"Enter locality"}
        type={"text"}
        value={city}
        onChange={(e:any) => setCity(e.target.value)}
      ></Input>

      <Input
        label={"State"}
        placeholder={"Enter state"}
        type={"text"}
        value={state}
        onChange={(e:any) => setState(e.target.value)}
      ></Input>

      <Input
        label={"Zip Code"}
        placeholder={"Enter zip code"}
        type={"text"}
        value={zipCode}
        onChange={(e:any) => setZipCode(e.target.value)}
      ></Input>

      <Input
        label={"Country"}
        placeholder={"Enter country"}
        type={"text"}
        value={country}
        onChange={(e:any) => setCountry(e.target.value)}
      ></Input>

      <Input
        label={"Latitude"}
        placeholder={"Enter latitude"}
        type={"text"}
        value={lat}
        onChange={(e:any) => setLat(e.target.value)}
      ></Input>

      <Input
        label={"Longitude"}
        placeholder={"Enter longitude"}
        type={"text"}
        value={lng}
        onChange={(e:any) => setLng(e.target.value)}
      ></Input>
      <DropDown dropdowndiv=' mt-10' labelMargin="mb-1" label="Address Type" value={addresstype} onChange={(e) => setAddressType(e.target.value)} className=' '>
                    <option value=''>Select Type</option>
                    <option value='Residencial'>Residencial</option>
                    <option value='Commercial'>Commercial</option>
                </DropDown>
                <Input InputDivClass="mt-10" label={'No of Rooms'}
                    placeholder={'No of Rooms'}
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input InputDivClass="mt-10" label={'No of Bathrooms'}
                    placeholder={'No of Bathrooms'}
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input InputDivClass="mt-10" label={'Total Area'}
                    placeholder={'square feet'}
                    type={'text'}
                    inputClass = ''>
                </Input>   
      <div className='mt-10'>
              <label htmlFor="Remarks" className=" mb-2 text-sm font-satoshiBold flex items-center">Remarks</label>
<textarea id="Remarks" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder="Write your note here..."></textarea>        
</div>
    </form>
  </div>
  )
}
