import React, { ForwardRefRenderFunction, forwardRef, useEffect, useState } from 'react'
import { Input } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
interface data {
  first_name: string;
  last_name: string;
  password: string;
}

 const NameChange: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const intl = useIntl()
  const [currentPassword, setCurrentPassword] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [errors, setErrors] = useState<any>({});

  const NameChangeSchema = Yup.object({
    first_name:Yup.string().required('INPUT.VALIDATION.FIRSTNAME'),
    last_name:Yup.string().required('INPUT.VALIDATION.LASTNAME'),
    password:Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters')
  })
  useEffect(()=> {
    props?.HandleData({
      firstName:firstName,
      lastName:lastName,
      password: currentPassword
    })
    if(Object.keys(errors).length !== 0 ){
      validateData();
    }
  },[firstName,lastName, currentPassword]);

  const validateData = async () =>  {
    const data:data = {
      first_name:firstName,
      last_name:lastName,
      password:currentPassword,
    }
    let isValid: boolean;
    try {
      await NameChangeSchema.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      props?.HandleData({
      first_name:firstName,
      last_name:lastName,
      password:currentPassword,
      })
      isValid = true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid = false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));
  return (
    <>
    <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME",
                    defaultMessage: "First Name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
                    defaultMessage: "Enter your first name",
                  })}
                  type={"text"}
                  onChange={(e)=> setFirstName(e.target.value)}
                  error={errors?.first_name}
                  value={firstName}
                ></Input>
                <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.LASTNAME",
                    defaultMessage: "Last Name",
                  })}
                  onChange={(e)=> setLastName(e.target.value)}
                  error={errors?.last_name}
                  value={lastName}
                  placeholder={"Enter your last name"}
                  type={"text"}
                ></Input>
    <Input type='password' 
    InputDivClass="mt-5" 
    inputClass = "border border-zinc-800 rounded-lg h-11" 
    label={<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.INPUT.CURRENTPASS" defaultMessage="Current password" />}
     value={currentPassword} onChange={(e)=> setCurrentPassword(e.target.value)}></Input>
    </>
  )
}
export default forwardRef(NameChange);