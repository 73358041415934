import React, {useState, useEffect} from 'react'
import AuthNavComponent from '../../module/auth/AuthNav'
import Footer from '../../module/Footer'
import { Filter } from '../../company-display/component/Sidebar/Filter';
import { Pageination } from 'sr/helpers/ui-components/Pageination';
// import { DemoCompaniesData, getLenght} from 'sr/DemoCompanies';
import JobItemCard from '../component/JobItemCard';
import { FormattedMessage } from 'react-intl';
export const IndividualJobs = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    const [jobs, setJobs] = useState<any[] | undefined>(undefined);
    const [totalPage, settotalPage] = useState<number>(1);
    // useEffect(() => {
    //   const alldata =  DemoCompaniesData(page, limit)
    //   setJobs(alldata)
    //   const totalpage = Math.ceil(getLenght()/limit)
    //   settotalPage(totalpage)
    // }, [page, limit])

    const handlePageChange = (value) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
    setPage(value)
    }
    const data: any = {
        id: '398598-98w98e9-9090',
        jobTitle: 'Executive Housekeeper',
        location: 'Pune, Maharashtra',
        jobType: 'Regular/Permenent',
        schedule: 'Morning shift',
        description: 'Supervises all housekeeping employees, hires new employees as needed, discharges employees when necessary and take disciplinary actions when policies are not followed. Evaluates employees in order to upgradethem when openings arise. Plans the work for the housekeeping departmentand distributes assignments accordingly. Assigns regular duties and special duties for the housekeeping staff. Schedulesemployees and assigns extra days off according to occupancyforecast. Maintains a time logbook of all employees within the department. Recruit and train new employees. Assigns new employees to work with experienced help. Checks on the work of these employees occasionally and observes the report made by the supervisors. Approves all supply requisitions, such as those for spreads and bathroom rugs. Maintains a lost-and-found department and is responsible for all lost-and-found items. Determines the rightful owner and send correspondences.',
    };
  return (
    <div>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
        <div className='font-bold font-satoshiBold text-xl mb-5 lg:text-left text-center'>
        14 <FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.TITLE' defaultMessage='Jobs based on Your Desired Preferences' />
        </div>
        <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-0 pt-4'>
            <div className='col-span-1'>
            <Filter />
            </div>
        <div className="col-span-3">
        {
            jobs?.map((datI) => {
                return <JobItemCard jobs={datI} link="/individual/job-detail"></JobItemCard>
            })
        }
        <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
        </div>
        </div>
    </div>
   <Footer /> 
</div>
  )
}
