
import React, {useState} from "react";
import { Input } from "sr/helpers";
import { MainWrapper } from "../component/MainWrapper";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
export const JobPostDetails = () => {
  const intl = useIntl()
    const [street, setStreet] = useState("")
    const [unitStreet, setUnitStreet] = useState("")
    const [showAddress, setShowAddress] = useState(true)
    const [showtasklist, setShowTasklist] = useState(true)
    const HandleAddress = ()=> {
        setShowAddress(false)
    }
    const topHeader = (
      <div className="bg-white flex justify-center w-full space-x-2 border border-neutral-200 py-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
<p><FormattedMessage id="INDIVIDUAL.BOOKTASK.TOP.DESCRIPTION" defaultMessage="Tell us about your task. We use these details to show cleaner in your area who fit your needs." />
</p>
      </div>
    )
  return (
    <MainWrapper topHeader={topHeader}>
    <div className=''>
        <div className="lg:mx-10 mx-0 md:mx-5 py-5">
        <p className="text-lg font-satoshiBold pb-2">Home Cleaner</p>
        <div className="bg-white rounded-lg p-4 text-base border border-neutral-300 mb-3">
            <div className="flex justify-between">
        <p className="text-base pb-3 font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.INPUT.TASK.LOCATION.TITLE" defaultMessage="Your task location" /></p>
        {!showAddress && 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      
        }
        </div>
        {showAddress ? <>
        <Input onChange={(e:any)=> setStreet(e.target.value)} inputClass="border border-neutral-400 rounded-lg h-11" InputDivClass="mt-0 mb-3" placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.STREET.PLACEHOLDER", defaultMessage:"Street address"})}></Input>
        <Input onChange={(e:any)=> setUnitStreet(e.target.value)}  inputClass="border border-neutral-400 rounded-lg h-11" InputDivClass="mt-0 mb-3" placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.UNIT.PLACEHOLDER", defaultMessage:"Unit or Apt #"})}></Input>
        <div className="flex justify-center pt-3">
        <div onClick={HandleAddress} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" ><FormattedMessage id="INDIVIDUAL.BOOKTASK.CONTINUE.BUTTON" defaultMessage="Continue" /></div>
        </div>
        </>:
        <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
<p>{street} {unitStreet}</p>
            </div>
<p className="text-[#2557A7]">Good News JMM is Available in your area</p>
        </div> }
        </div>
        <div className="bg-white rounded-lg p-4 text-base border border-neutral-300 mb-3">
            <div className="flex justify-between">
        <p className="text-base pb-3 font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.INPUT.TASK.TITLE" defaultMessage="Tell us the details of your task" /></p>
        {!showAddress && 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      
        }
        </div>
        <textarea rows={3} className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" 
        placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.PLACEHOLDER", defaultMessage:"Provide a summary of what you need done for your tasker."}) }>
        </textarea>
        <div className="flex justify-center pt-3">
        <Link to={'/individual/cleaner-profiles'} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" ><FormattedMessage id="INDIVIDUAL.BOOKTASK.CLEANERSSHOW.BUTTON" defaultMessage="See Cleaner & Prices" /></Link>
        </div>
        </div>
    </div></div>
    </MainWrapper>
  );
};
