import React, {useState} from 'react'
import { Checkbox } from 'sr/helpers'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton'
import { ChecklistForm } from './forms/ChecklistForm'
import { TaskForm } from './forms/TaskForm'
import { AllChecklist } from '../ChecklistTemplate/AllChecklist'
import { Task } from './Tables/Task'
import { FormattedMessage } from 'react-intl'
export const ChecklistAdd = () => {
    const [GlobalTemplate, setGlobalTemplate] = useState(false)
    const [model, setModel] = useState(false)
    const [copyChecklistid, setCopyChecklistId] = useState(-1)
    const [addMore, setAddMore] = useState(false)
    const [checklistdetails, setchecklistDetails] = useState({})
    const [task, settask] = useState({})
    const [ChecklistPopup, setChecklistPopup] = useState(false)
    const ChecklistHandle = (index) => {
        settask({task:"Clean Trash", Description:"Clean should be clean in a better way and make attention.", Type:"Mandatory"})
        setCopyChecklistId(index)
    }
    const Savechecklist = () => {
        setchecklistDetails({"checklistname":"my dailychecklist"})
    }
    const saveTaskHandle = () => {
        setAddMore(false)
        settask({task:"Clean Trash", Description:"Clean should be clean in a better way and make attention.", Type:"Mandatory"})
    }
    const onCheckModel = (data) => {
        setChecklistPopup(false)
        setModel(data)
      }
  return (
   <>
    { Object.keys(checklistdetails).length === 0 &&
    <>
    <ChecklistForm />
    <Button text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.SAVE_BUTTON" defaultMessage="Save" />} onClick={Savechecklist}></Button>
    </>
    
    }
       { Object.keys(checklistdetails).length === 1 &&
       <>
       <div className='mt-5'> 
       
    <Checkbox id="Global" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.GLOBAL.TITLE" defaultMessage="Import from global Checklist" />} onChange={()=> setGlobalTemplate(!GlobalTemplate)} value={GlobalTemplate}></Checkbox>
    </div>
    {GlobalTemplate ? null :
    <>
    <TaskForm />
    <Button text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.SAVE_BUTTON" defaultMessage="Save" />} onClick={saveTaskHandle}></Button>
    </>
    }
    </>
     }
                    {GlobalTemplate ? copyChecklistid === -1 &&
                    <AllChecklist  ChecklistHandle={ChecklistHandle}/>
                    :null}
                  
                                            {copyChecklistid > -1? 
        <>    
    
    <div className="overflow-x-auto mt-10 rounded-[20px] border border-neutral-200">
        {Object.keys(task).length > 0 ?
      <Task onClick={() => setModel(true)} />
        :null}
        </div>
         </>  
    :null}
    {addMore && <TaskForm /> }
    
              {copyChecklistid > -1 && addMore && <Button onClick={saveTaskHandle} text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.SAVE.TASK.BUTTON" defaultMessage="Save Task" />}></Button> }
              
            {copyChecklistid > -1 && !addMore && <Button onClick={() => setAddMore(true)} text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.BUTTON" defaultMessage="Add More Task" />}></Button> }
        
            </>  )
}
